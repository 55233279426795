.bl-instagramfeed {
    .items {
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        gap:1rem;
        
        img {
            display:block;
            width:100%;
            object-fit:cover;
            object-position:center center;
            aspect-ratio:4 / 5;
        }
    }
    .followus {
        @include font-size(1.5, math.div(50, 16));
        text-transform: uppercase;
        font-weight: 700;
        text-align:center;
        margin-top:0.5em;
        
        .icon {
            vertical-align: -0.15em;
            margin-right: 0.1em;
        }
    }
}
