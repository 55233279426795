.bl-textwithimage {
    .ctrimagehover {
        position: relative;
        .image2 {
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            
            transition: opacity .2s;
            opacity: 0;

            &:hover {
                opacity: 1;
            }
        }
    }
    @include breakpoint(large) {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:rem-calc(40);

        &.imgpos-right .ctrimage {
            order:1;
        }
    }
    @include breakpoint(medium down) {
        .ctrimage {
            margin-bottom: 1em;
        }
    }

    h2 {
        font-weight:300;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin:0;
    }
    h1 {
        margin-top:-0.25em;
        margin-bottom:0.25em;
        color:$orange;
        text-transform: uppercase;
    }
}
