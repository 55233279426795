

//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

body {
  //Dynamic Font Size: mobile, desktop
  @include font-size(1, math.div(22, 16));

  font-family: 'Poppins', sans-serif;
  font-weight: 300;

  //color:#D9D7D7;
  color:#FFFFFF;
}

strong {
  font-weight: 600;
}

h1 {
  font-weight: 600;
  @include font-size(2, math.div(50, 16));
  margin:0 0 1em 0;
}
h2 {
  font-weight: 600;
  @include font-size(1.5, math.div(30, 16));
  margin:0 0 1em 0;
  line-height:1.2;
}
p {
  margin:0 0 1em 0;
}

.textinvertcolor {
  color:#000000;
}

a {
  text-decoration: none;
  color:inherit;
}

a:focus {
  outline: none;
}


.bl-richtext {
  &.geschaeft {
    h2 {
      margin-top:1.25em;
      margin-bottom:0.25em;
      text-transform: uppercase;
    }
  }
}