ul:not(.menu):not(.form):not(.accordion) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      //content: "– ";
      content: "· ";
      font-weight: bold;
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}
