.bl-iconlist {
    .items {
        display:grid;
        gap:2em 3em;
        justify-content: center;
        grid-template-columns: repeat(2, auto);
        @include breakpoint(large) {
            grid-template-columns: repeat(3, auto);
            gap:2em 7em;
        }
    }
    .item {
        text-align: center;
        @include font-size(1.5, math.div(30, 16));
        transition:color .2s;
        
        .icon {
            height:4.5em;
        }

        &.hasanchor {
            cursor: pointer;
            &:hover {
                color:$orange;
            }
        }
    }
}