.bl-title {
    text-align: center;
    h1 {
        @include font-size(1.5, math.div(30, 16));
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 0.1em;
        margin:0;
    }
    h2 {
        @include font-size(2, math.div(70, 16));
        font-family: "MellyThoughtie", sans-serif;
        color:$orange;
        font-weight: 400;
        line-height: 1.2;
        margin:0;
        margin-top:0.3em
    }
}