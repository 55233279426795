/*
.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $light-gray;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &.opaque {
    @include shadow(0 2px 7px 0 rgba($black, 0.1));
  }
}
*/

header {
  .messagebar {
    background-color: #333333;
    padding-top:0.25em;
    padding-bottom:0.25em;
    @include font-size(0.8, math.div(16, 16));
    font-weight: 600;

    .ctrmessage {
      display:flex;
      justify-content: center;
      align-items: center;
      gap:0.5em;
    }
    .icon {
      height:rem-calc(34);
    }
  }
  .logobar {
    background-color: #191919;
    padding-top:0.5em;
    padding-bottom:0.5em;

    .logo {
      width:min(rem-calc(260), 40vw);
    }
    .ctrheader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ctrbuttons {
        display:flex;
        gap:3rem;
        @include breakpoint(medium down) { 
          display: none;
        }        
      }
    }
  }
  .button {
    border:2px solid #D9D7D7;
    background-color: transparent;
    color:inherit;
    font-weight: 400;
    padding:0.25em 0.75em;

    transition: color 0.2s, border-color 0.2s;
    &:hover {
      background-color: transparent;
      color:$orange;
      border-color: $orange;
    }
  }
  .ctrmobilebuttons {
    display:flex;
    justify-content: space-between;
    gap:0.5em;
    margin-top:1em;
    @include breakpoint(small down) { 
      .button {
        font-size: 0.8rem;
      }
    }
    @include breakpoint(medium) { 
      justify-content: flex-end;
    }
    @include breakpoint(large) { 
      display:none;
    }
  }
}

#menuiconbar {
  background-color: #383838;
  padding-top:1.5em;
  padding-bottom:1.5em;

  position:sticky;
  top:0;
  z-index:10;

  @include breakpoint(medium down) { 
    display: none;
  }        
  .items {
    display:flex;
    justify-content: center;
    gap:3em;
    .item {
      text-align: center;
      .label {
        @include font-size(1, math.div(18, 16));
      }
      .icon {
        height:1.5em;
      }

      transition:color 0.2s;
      &:hover {
        color:$orange;
      }
      &.active {
        color:$orange;
      }
    }
  }
}
