/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/348c73");
  
/*
@font-face {
    font-family: 'ErnestandEmilySlanted';
    src: url('/assets/app/webfonts/348C73_0_0.woff2') format('woff2'),
        url('/assets/app/webfonts/348C73_0_0.woff') format('woff');
}
*/
 
@font-face {
    font-family: 'MellyThoughtie';
    src: url('/assets/app/webfonts/MellyThoughtie.otf') format('opentype'),
        url('/assets/app/webfonts/MellyThoughtie.ttf') format('truetype');
}
