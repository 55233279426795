.bl-team {
    h1 {
        margin:0;
    }
    h2 {
        text-transform: uppercase;
        @include font-size(1, math.div(30, 16));
        font-weight: 300;
        letter-spacing: 0.1em;
        margin-top:-0.25em;
    }
    @include breakpoint(large) {
        .item {
            display:grid;
            gap:rem-calc(20);
            grid-template-columns: repeat(2, 1fr);

            .ctrphoto {
                aspect-ratio: 1;
                align-self: stretch; //falls zu viel Text, dann Bild erhoehen
                position: relative;
                .photo {
                    display: block;
                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                    object-position: center center;
                }
                .photo2 {
                    position: absolute;
                    left:0;
                    top:0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    
                    opacity: 0;
                    transition: opacity 0.2s;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            .info {
                justify-self: center;
                align-self: center;
                padding:0.25em 3em;
            }
        }
        .item:nth-child(even) {
            .ctrphoto {
                order:1;
            }
        }
    }
    @include breakpoint(medium down) {
        .item .ctrphoto {
            margin-bottom:1em;
        }
        .item .photo {
            display: none;
        }
        .item + .item {
            margin-top:3em;
        }
    }

}