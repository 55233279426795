footer {
  background-color: #191919;
  @include font-size(1, math.div(18, 16));

  .cols {
    display:grid;
    gap:rem-calc(75);
    @include breakpoint(large) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  /*
  .infotext {
    @include font-size(1, math.div(18, 16));
  }
  */

  .socialicons {
    display:flex;
    justify-content: space-between;
    width:85%;
    margin-top:2em;
    
    .icon {
      display:block;
      height:rem-calc(44);
    }
  }

  .button {
    /*
    border:2px solid currentColor;
    background-color: transparent;
    color:currentColor;
    */
    margin-top: 2em;
  }

  h1 {
    font-weight: 600;
    text-transform: uppercase;
    @include font-size(1.2, math.div(22, 16));
  }
  h2 {
    font-weight: 300;
    @include font-size(1.2, math.div(22, 16));
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
  }

  .logo {
    display:block;
    margin-bottom: 1em;
    width:rem-calc(260);
    max-width: min(100%, 40vw);
  }
  .map {
    margin-top: 2em;
  }

  .linkbar {
    font-weight: 600;
    justify-content: center;
    margin-top:3em;
    @include breakpoint(large) {
      display:flex;
      gap:5em;
    }

    .links {
      text-transform: uppercase;
      @include breakpoint(large) {
        display: flex;
        gap:2em;
      }
      @include breakpoint(medium down) {
        .separator {
          display:none;
        }
        a {
          display: block;
        }
      }
    }
  }

  .parkplatzinfo {
    //color:#2ea2ff;
    .icon {
      height:1.5em;
      vertical-align: top;
    }
  }

}
