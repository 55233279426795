.bl-fadeslider {
    aspect-ratio: 16 / 5;
    position: relative;
    overflow: hidden;

    .slide {
        position: absolute;
        inset: 0;
        background-position: center center;
        background-size: cover;
        opacity: 0;

        &.initialslide {
            opacity: 1;
        }

        &.active {
            opacity: 1;
            transform: scale(1.2);
            transition: transform 10s linear, opacity 2s linear;
        }
    }
}
