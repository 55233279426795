.bl-gallery {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:rem-calc(20);
    @include breakpoint(medium) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
    }

    img {
        display: block;
        width: 100%;
    }
}

