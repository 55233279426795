body {
    --mpbaseunit: 1.5rem;
    @include breakpoint (small down) {
        --mpbaseunit: 0.75rem;
    }
}
@for $i from 1 through 5 {
    .pt_#{$i} {
        padding-top:calc(#{$i} * var(--mpbaseunit));
    }
    .pb_#{$i} {
        padding-bottom:calc(#{$i} * var(--mpbaseunit));
    }

    .mt_#{$i} {
        margin-top:calc(#{$i} * var(--mpbaseunit));
    }
    .mb_#{$i} {
        margin-bottom:calc(#{$i} * var(--mpbaseunit));
    }
}

section {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &.topline {
        border-top:3px solid #FFFFFF;
    }
}