.bl-swiper {
    --swiper-theme-color:#383838;
    .slide {
        img {
            display:block;
            width:100%;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display:none;
    }
}