.bl-blogdetail {
    .breadcrumb {
        margin-bottom: 1em;
        text-align: center;
    }
    .pubdate {
        text-align: right;
        text-transform: uppercase;
        margin-bottom: 0.5em;
    }
    .bloghero {
        margin-bottom: 2em;

        display:grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        
        .bigimg {
            display: block;
            width: 100%;
        }
        .navbtn {
            width:5rem;
            flex:0 0 auto;
            .icon {
                height:2em;
            }
            &.next {
                text-align: right;
            }
            &[href=''] {
                //hide empty links
                visibility: hidden;
                pointer-events: none;
            }
        }

        @include breakpoint(xxlarge) {
            margin-left:-5rem;
            margin-right:-5rem;
        }
        @include breakpoint(small down) {
            .navbtn {
                width:3rem;
            }
        }
    }
    .text {
        text-align: center;
    }
}