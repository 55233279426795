.bl-infomulticol {
    .cols {
        display:grid;
        gap:rem-calc(40);
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .col {
        text-align: center;
    }
    .image {
        display: block;
        width: 100%;
        margin-bottom:1.25em;
    }
    h2 {
        margin-bottom: 0.75em;
    }
}