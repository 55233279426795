.bl-textwithimage + .bl-textwithimage {
    margin-top:1em;
}

.bl-title {
    margin-bottom: 1em;
}
.bl-title + .bl-team {
    margin-top: 3em;
}
.bl-title + .bl-iconlist {
    margin-top: 2em;
}

.bl-gallery {
    margin-bottom:1em;
}
.bl-gallery + .button {
    margin-top:3em;
}

.bl-richtext + .bl-gallery {
    margin-top:2em;
}
.bl-richtext + .button {
    margin-top:3em;
}

.bl-infomulticol + .bl-richtext {
    margin-top:2em;
}
.bl-richtext + .bl-infomulticol {
    margin-top:3em;
}

@include breakpoint(medium down) {
    .bl-textwithimage + .bl-textwithimage {
        margin-top:4em;
    }
}