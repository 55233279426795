.bl-newsletterform {
    .infotext {
        text-align: center;
    }
}

form {
    input[type=text],
    input[type=email],
    input[type=file] {
        //border:2px solid #D9D7D7;
        //background-color: rgba(255, 255, 255, 0.25);
        //border-radius: rem-calc(10);
        //padding:0.5em 0.75em;
        border:none;
        background-color: transparent;
        border-bottom:2px solid $orange;
        padding:0.5em 0;
        width:100%;

        font-family: inherit;
        color:inherit;
    }
    input[type=checkbox] {
        border:2px solid #D9D7D7;
    }
    input::file-selector-button {
        background-color: #d9d7d7;
        border-radius: 8px;
        border: none;
        padding: 6px 18px;
        font-size: 0.8em;
        font-weight: 700;
        margin-right: 14px;
    }      

    .field {
        margin-top:rem-calc(20);
    }

    .ctrcheckbox {
        display: flex;
        gap:0.5em;
        justify-content: flex-start;
        align-items: center;
        margin-top:rem-calc(20);
    }

    .button {
        margin-top:1.5em;
    }
}