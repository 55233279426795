.bl-blogoverview {
    h1 {
        text-align: center;
    }
    .showall {
        display: block;
        text-align: right;
        font-weight: 700;
        margin-bottom: 1em;

        transition:color 0.2s;
        &:hover {
            color:$orange;
        }
    }

    .ctryearselect p {
        margin-bottom:0.5em;
    }
    select {
        border:2px solid #D9D7D7;
        background-color: transparent;
        color:inherit;
        padding:0.5em 0.75em;
        width:auto;
        border-radius: rem-calc(10);
        margin-bottom: 2em;
        min-width:12em;

        option {
            background-color:#383838;
        }
    }

    .items {
        display:grid;
        gap:rem-calc(40 20);
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .item {
        .image {
            display: block;
            width:100%;
        }
        h2 {
            color:$orange;
            margin-top:0.5em;
            margin-bottom: 0.2em;
        }
        .readmore {
            display: block;
            font-weight:600;
            margin-top:0.5em;

            transition:color 0.2s;
        }

        &:hover .readmore {
            color:$orange;
        }
    }
}