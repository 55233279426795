ul.accordion {
    margin: 0;
    padding: 0;
}

.bl-preise {
    .accordion-title {
        color:inherit;
        transition:color 0.2s;
        &:hover {
            color:$orange;
        }
        .icon {
            height:auto;
            width:1.5em;
            transition: transform 0.2s;
        }
    }
    .accordion-item.is-active {
        .icon {
            transform:rotateZ(180deg);
        }
    }
    h1 {
        @include font-size(1.5, math.div(50, 16));
        text-transform: uppercase;
        font-weight: 600;
        margin:0;
        color:inherit;
    }
    h2 {
        @include font-size(1.5, math.div(50, 16));
        text-transform: uppercase;
        font-weight: 600;
        margin:0;
        margin-top:0.75em;
        color:$orange;
    }
    h3 {
        @include font-size(1, math.div(30, 16));
        text-transform: uppercase;
        font-weight: 600;
        margin:0;
        margin-top:0.5em;
        color:inherit;
    }
    table {
        width:100%;
        
        td {
            vertical-align: top;
        }
        td.star1,
        td.star2 {
            text-align: right;
            vertical-align: bottom;
            padding-right: 0.8em;
            font-weight: 600;
        }
        td.preis1,
        td.preis2 {
            width:4em;
            text-align: right;
        }
    }
    .accordion-title {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
}