.button {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  @include font-size(1, math.div(18, 16));
  border-radius: rem-calc(10);
  padding:0.7em 2em;
  line-height:1.5;
  cursor: pointer;

  background-color: #D9D7D7;
  color:#000000;

  display:inline-flex; //icon+text
  gap:0.5em;
  align-items: center;

  &.block {
    display: flex;
    justify-content: center;
    width:fit-content;
  }
  &.w100 {
    width:100%;
  }
  &.centered {
    margin-left: auto;
    margin-right: auto;
  }
}

.button {
  transition: color 0.2s, background-color 0.2s;
  &:hover {
    color:$white;
    background-color: $orange;
  }
}