.bl-jobapplicationform {
    h2 {
        font-weight: 300;
        @include font-size(1.5, math.div(30, 16));
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin:0;
        text-align: center;
    }
    h1 {
        margin-bottom:0.5em;
        text-align: center;
    }

    /*
    input[type=text],
    input[type=email],
    input[type=file] {
        border-color:$orange;
    }
    input[type=checkbox] {
        border-color:$orange;
    }
    */

    .button {
        margin-top:1.5em;
    }

    .field,
    .ctrcheckbox {
        margin-top:rem-calc(20);
    }
    @include breakpoint(large) {
        .mc2col {
            display:grid;
            gap:rem-calc(0 20);
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
